import { Injectable } from '@angular/core';
import { combineLatest, first, map, Observable, switchMap } from 'rxjs';
import { UserConfigTypeEnum } from '../../../../enums/user-config-type.enum';
import { DeviceShortInterface } from '../../../../interfaces/device.interface';
import { UserConfigInterface } from '../../../../interfaces/user-config.interface';
import { ApiResourceDevicesService } from '../../../../modules/api/services/api-resource-devices/api-resource-devices.service';
import { ApiResourceUserConfigService } from '../../../../modules/api/services/api-resource-user-config/api-resource-user-config.service';

@Injectable()
export class DevicesDropdownDataService {
  private configType = UserConfigTypeEnum.HOME_PAGE_DEVICES;

  constructor(
    private apiResourceUserConfigService: ApiResourceUserConfigService,
    private apiResourceDevicesService: ApiResourceDevicesService,
  ) {}

  getDropdownDevices$(): Observable<DeviceShortInterface[]> {
    return combineLatest([this.getAllDevices$(), this.getConfigDevices$()]).pipe(
      first(),
      map(([allDevices, userConfig]) => allDevices
        .filter(device => userConfig.deviceConfig.includes(device.id) && userConfig.siteConfig.includes(String(device.site)))
        .sort((a: DeviceShortInterface, b: DeviceShortInterface) => a.name.localeCompare(b.name)),
      ),
    );
  }

  getConfigDevices$(): Observable<{
        deviceConfig: string[];
        siteConfig: string[];
        statusConfig: string[];
      }> {
    // the config is updated while selecting robots on the landing page dropdown list filter
    // we want to show only the robots that are checked on the main page
    return this.apiResourceUserConfigService.getConfigs$(this.configType).pipe(
      switchMap((configs: Required<Omit<UserConfigInterface, 'config'>>[]) => {
        return this.apiResourceUserConfigService.getConfig$(configs[0].id);
      }),
      map((response: Required<UserConfigInterface<{
        deviceConfig: string[];
        siteConfig: string[];
        statusConfig: string[];
      }>>) => response.config),
    );
  }

  getAllDevices$(): Observable<DeviceShortInterface[]> {
    return this.apiResourceDevicesService.getDevicesShortList$();
  }
}
