<header>
  <div class="kgn-container" [class.hamburger-open]="isHamburgerOpen">
    <div class="logo">
      <a href="/">Kogena</a>
    </div>

    <div class="user-tools" [ngClass]="isUserToolOpen ? 'open' : 'close'">
      <div class="header-devices">
        <app-device-status-icon
          *ngIf="device"
          [device]="device"
        ></app-device-status-icon>

        <app-device-dropdown
          *ngIf="device"
          [currentDeviceId]="device.id"
        ></app-device-dropdown>
      </div>

      <div id="user-toggle" class="ico ico__user" (click)="isUserToolOpen = !isUserToolOpen">
        <div class="screen-reader-text" i18n>User menu</div>
      </div>
      <div class="user-tools__nav">
        <div class="name">{{username}}</div>
        <a (click)="openAdminModal()" i18n>Password Change</a>
        <a (click)="onLogout()" i18n>Logout</a>
        <div></div>
      </div>
    </div>

    <button id="nav-toggle"
      (click)="isHamburgerOpen = !isHamburgerOpen"
      (blur)="isHamburgerOpen = false">
      <span></span>
      <span></span>
      <span></span>
      <div class="screen-reader-text" i18n>Site navigation</div>
    </button>

    <ul class="main-nav__nav" #menu>
      <ng-container *ngIf="availableViews">
        <li>
          <a routerLink="/" i18n>Dashboard</a>
        </li>
        <li *ngFor="let availableView of availableViews">
          <a routerLink="{{ availableView.base_url }}{{ device!.id }}"
              [routerLinkActive]="'active'"
              (isActiveChange)="this.storeCurrentView($event, availableView)"
          >{{ availableView.display_name }}</a>
        </li>
      </ng-container>
    </ul>

    <div class="spacer"></div>
  </div>
</header>
