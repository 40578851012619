<mat-dialog-content class="modal-content" i18n="@@privacy-policy">
  <h1>PRIVACY POLICY</h1>
  <h2>§1 General provisions</h2>
  <ol>
    <li>
      By using our services, you entrust us with your information. This Privacy Policy is only intended to help you understand what
      information and data is collected and for what purpose and what we use it for. This data is very important to us, so please read this
      document carefully, as it defines the rules and methods of processing and protecting personal data. This document also defines the
      rules for the use of "Cookies".
    </li>
    <li>
      We hereby declare that we comply with the principles of personal data protection and all legal regulations provided for in the
      Personal Data Protection Act and Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the
      protection of individuals with regard to processing personal data and on the free movement of such data, and repealing Directive 95/46
      / EC.
    </li>
    <li>
      The person whose personal data is processed has the right to contact us in order to obtain comprehensive information on how we use his
      personal data. We always try to inform you clearly about the data we collect, how we use it, what purposes it is intended for and to
      whom we pass it on, what we ensure the protection of this data when transferring it to other entities and we provide information about
      the institutions that should be contacted in the event of doubts.
    </li>
  </ol>
  <h2>§2 Privacy Policy</h2>
  <ol>
    <li>
      We take privacy seriously. We are characterized by respect for privacy and the fullest possible and guaranteed comfort of using our
      services.
    </li>
    <li>
      We value the trust that Users place in us by entrusting us with their personal data in order to complete the order. We always use
      personal data fairly and in such a way as not to disappoint this trust, only to the extent necessary to perform the order, including
      its processing.
    </li>
    <li>
      You have the right to obtain clear and complete information on how we use your personal data and for what purposes it is needed. We
      always clearly inform about the data we collect, how and to whom we transfer it and provide information about entities to contact in
      case of doubts, questions or comments.
    </li>
    <li>
      In case of doubts regarding the use of your personal data by us, we will immediately take steps to clarify and dispel such doubts, we
      will fully and comprehensively answer all related questions.
    </li>
    <li>
      We will take all reasonable steps to protect Users' data against improper and uncontrolled use and to secure them in a comprehensive
      manner.
    </li>
    <li>
      We will comply with all applicable data protection laws and regulations and we will cooperate with data protection authorities and law
      enforcement agencies authorized to do so. In the absence of data protection regulations, we will act in accordance with generally
      accepted data protection principles, principles of social coexistence and established customs.
    </li>
    <li>
      The exact method of personal data protection is included in the personal data protection policy (GDPR: security policy, personal data
      protection regulations, IT system management manual). For security reasons, due to the procedures described in it, it is available
      only to state control authorities.
    </li>
    <li>
      The administrator of your personal data is KOGENA Sp. z o.o. 8971852678, Pl. Solny 15, 50-062, Wrocław, e-mail: kontakt&#64;kogena.com,
      tel. +48 533 216 454
    </li>
    <li>
      The legal basis for the processing of your personal data is Art. 6 sec. 1 lit. b) GDPR. Providing data is not mandatory, but necessary
      to take appropriate steps prior to the conclusion of the contract and its implementation. We will transfer your personal data to other
      recipients entrusted with the processing of personal data on behalf of and for our benefit. Your data will be transferred on the basis
      of art. 6 sec. 1 lit. f) GDPR, where the legitimate interest is the proper performance of contracts / orders. In addition, we will
      share your personal data with other business partners. We store the collected personal data in the European Economic Area ("EEA"), but
      they may also be transferred to a country outside this area and processed there. Each operation of transmitting personal data is
      performed in accordance with applicable law. If data is transferred outside the EEA, we use standard contractual clauses and the
      privacy shield as safeguards in relation to countries for which the European Commission has not found an adequate level of data
      protection.
    </li>
    <li>
      Your personal data related to the conclusion and implementation of the contract for the implementation of contracts will be processed
      for the period of their implementation, as well as for a period not longer than provided for by law, including the provisions of the
      Civil Code and the Accounting Act, i.e. not longer than 10 years, counting from the end of the calendar year in which the last
      contract was performed.
    </li>
    <li>Your personal data processed in order to conclude and perform future contracts will be processed until the objection is raised.</li>
    <li>
      You have the right to: access your personal data and receive a copy of the personal data subject to processing, rectify your incorrect
      data; request deletion of data (the right to be forgotten) in the event of circumstances provided for in art. 17 GDPR; requests to
      limit data processing in the cases specified in art. 18 GDPR, to object to data processing in the cases specified in art. 21 GDPR, to
      transfer the provided data, processed in an automated manner.
    </li>
    <li>
      If you believe that your personal data is being processed unlawfully, you can lodge a complaint with the supervisory authority
      (Personal Data Protection Office, ul. Stawki 2, Warsaw). If you need additional information related to the protection of personal data
      or If you have any questions about how to handle personal data, please contact us via the page from which the user has been redirected
      to this Privacy Policy. The request for contact will be immediately forwarded to the appropriate person appointed for this.
    </li>
    <li>
      You always have the right to notify us if:
      <ol type="a">
        <li>no longer wants to receive information or messages from us in any form;</li>
        <li>would like to receive a copy of their personal data that we have;</li>
        <li>correct, update or delete your personal data in our records;</li>
        <li>would like to report violations, improper use or processing of their personal data.</li>
      </ol>
    </li>
    <li>To help us answer or comment on the information provided, please provide your name and surname and further details.</li>
  </ol>
  <h2>§3 The scope and purpose of collecting personal data</h2>
  <ol>
    <li>
      We process the necessary personal data in order to provide services and for accounting purposes, and only for such purposes as:
      <ol type="a">
        <li>in order to place an order,</li>
        <li>in order to conclude a contract, make a complaint and withdraw from the contract,</li>
        <li>issuing a VAT invoice or other receipt.</li>
      </ol>
    </li>
    <li>
      We collect, process and store the following user data:
      <ol type="a">
        <li>first name and last name,</li>
        <li>tax identification number (NIP),</li>
        <li>e-mail address (e-mail),</li>
        <li>telephone number (mobile, landline),</li>
        <li>information about the web browser used,</li>
        <li>other personal data provided voluntarily to us.</li>
      </ol>
    </li>
    <li>Providing the above-mentioned data is completely voluntary, but also necessary for the full implementation of the services.</li>
    <li>
      Purpose of collecting and processing or using data by us:
      <ol type="a">
        <li>direct marketing, archival purposes of advertising campaigns;</li>
        <li>fulfillment of obligations imposed by law by collecting information about undesirable activities;</li>
      </ol>
    </li>
    <li>
      We may transfer personal data to servers located outside the user's country of residence or to related entities, third parties based
      in other countries, including countries from the EEA (European Economic Area, EEA - free trade zone and the common market) , covering
      the countries of the European Union and the European Free Trade Association EFTA) for the processing of personal data by such entities
      on our behalf in accordance with the provisions of this Privacy Policy and applicable laws, customs and regulations regarding data
      protection.
    </li>
    <li>
      Bearing in mind that in many countries to which this personal data is transferred, the same level of legal protection of personal data
      does not apply as in the user's country. The user's personal data stored in another country may be accessed in accordance with the law
      in force there, for example: courts, authorities responsible for law enforcement and national security, in accordance with the laws in
      force in that country. Subject to lawful requests for disclosure of data, we undertake to require entities that process personal data
      outside the user's country to take measures to protect data in an adequate manner to the regulation of their national law.
    </li>
  </ol>
  <h2>§4 Policy „Cookies”</h2>
  <ol>
    <li>
      We automatically collect information contained in cookies in order to collect User data. A cookie file is a small piece of text that
      is sent to the User's browser and which the browser sends back at the next visits to the website. They are mainly used to maintain a
      session, e.g. by generating and sending back a temporary identifier after logging in. We use "session" cookies stored on the User's
      end device until logging out, turning off the website or turning off the web browser, and "permanent" cookies stored on the User's end
      device for the time specified in the parameters of cookies or until their removal by the User.
    </li>
    <li>
      Cookies adapt and optimize the website and its offer for the needs of Users through such activities as creating page views statistics
      and ensuring security. Cookies are also necessary to maintain the session after leaving the website.
    </li>
    <li>
      The administrator processes the data contained in cookies each time the website is visited by visitors for the following purposes:
      <ol type="a">
        <li>Optimizing the use of the website;</li>
        <li>Identification of the Recipients as currently logged in;</li>
        <li>
          Adaptations, graphics, selection options and any other content of the website to the individual preferences of the Service
          Recipient;
        </li>
        <li>Remembering automatically and manually completed data from Order Forms or login details provided by the visitor;</li>
        <li>
          collecting and analyzing anonymous statistics showing how to use the website in the administration panel and Google Analytics
        </li>
        <li>
          Creating remarketing lists based on information about preferences, behavior, the way of using the interests of the Website and
          collecting demographic data, and then sharing these lists in AdWords and Facebook Ads.
        </li>
        <li>
          Creating data segments based on demographic information, interests, preferences in the selection of viewed products / services.
        </li>
        <li>Use of demographic and interest data in Analytics reports.</li>
      </ol>
    </li>
    <li>The user at any time using his web browser can completely block and delete the collection of cookies.</li>
    <li>
      Blocking by the User the possibility of collecting cookies on his device may make it difficult or impossible to use some of the
      website functionalities, to which the User is fully entitled, but in such a situation he must be aware of the functional limitations.
    </li>
    <li>
      An user who does not want to use "cookies" for the purpose described above may at any time delete them manually. To read the detailed
      instructions on how to proceed, visit the website of the manufacturer of the web browser used, which is currently used by the User.
    </li>
  </ol>
  <h2>§5 Rights and obligations</h2>
  <ol>
    <li>
      We have the right, and in cases specified by law, also a statutory obligation to provide selected or all information regarding
      personal data to public authorities or third parties who submit such a request for information on the basis of applicable provisions
      of Polish law.
    </li>
    <li>
      The User has the right to access their personal data that they provide, the User may correct and supplement this data at any time, and
      also have the right to request that it be removed from their databases or ceased to be processed, without giving any reason. In order
      to exercise their rights, the User may at any time send an appropriate message to the e-mail address or in another way that will
      deliver / transmit such a request.
    </li>
    <li>
      The User's request to delete personal data or to stop processing them by the User may result in the total impossibility of providing
      the services by or severely restricting them.
    </li>
    <li>We undertake to act in accordance with applicable law and the principles of social coexistence.</li>
    <li>
      Information on out-of-court settlement of consumer disputes. The entity authorized within the meaning of the Act on out-of-court
      settlement of consumer disputes is the Financial Ombudsman, whose website address is as follows: www.rf.gov.pl.
    </li>
  </ol>
  <h2>§6 Basic safety rules</h2>
  <ol>
    <li>
      Each user should take care of their own data security and the security of their devices that access the Internet. Such a device should
      absolutely have an antivirus program with an up-to-date, regularly updated database of virus definitions, types and types, a secure
      version of the web browser it uses and a firewall enabled. The user should check that the operating system and the programs installed
      on it have the latest and compatible updates, because the attacks use errors found in the installed software.
    </li>
    <li>
      Access data to services offered on the Internet are - e.g. logins, passwords, PINs, electronic certificates, etc. - should be secured
      in a place inaccessible to others and impossible to break in from the level of the Internet. They should not be disclosed or stored on
      the device in a form that allows unauthorized access and reading by unauthorized persons.
    </li>
    <li>
      Caution when opening strange attachments or clicking links in e-mail messages that we did not expect, e.g. from unknown senders or
      from the spam folder.
    </li>
    <li>
      It is recommended to run anti-phishing filters in the web browser, i.e. tools that check whether the displayed website is authentic
      and is not intended to be phishing, e.g. by impersonating a person or institution.
    </li>
    <li>
      Files should be downloaded only from trusted places, websites and websites. We do not recommend installing software from unverified
      sources, especially from unknown publishers with unverified opinion. This also applies to mobile devices, e.g. smartphones, tablets.
    </li>
    <li>
      When using your home Wi-Fi wireless network, set a password that is safe and hard to break, should not be any pattern or string that
      is easy to guess (e.g. street name, host name, birthday, etc.) . It is also recommended to use the highest possible standards of
      encryption of Wi-Fi wireless networks, which can be run on your equipment, eg WPA2.
    </li>
  </ol>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-button
    mat-dialog-close
    i18n
  >
    Close
  </button>
</mat-dialog-actions>
