<footer class="kgn-box-shadow">
	<div class="kgn-container">
		<nav>
			<a (click)="openContactDialog($event)" i18n>Contact</a>
			<a (click)="openPrivacyPolicyDialog($event)" i18n>Privacy Policy</a>
			<a (click)="openEuropeanFundsDialog($event)" i18n>European Union project</a>
		</nav>
		<div>
			&copy; KOGENA {{ currentYear }}
		</div>
	</div>
</footer>
