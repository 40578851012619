import { Component } from '@angular/core';
import { RobotFanucComponent } from '../../../modules/robot/robot-fanuc/robot-fanuc.component';

@Component({
  selector: 'app-robot-fanuc-page',
  template: `<app-robot-fanuc></app-robot-fanuc>`,
  standalone: true,
  imports: [RobotFanucComponent],
})
export class RobotFanucPageComponent {}
