import { CdkScrollable } from '@angular/cdk/scrolling';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { AlertService } from '../../../../shared/services/alert.service';
import { AdminOverallPasswordComponent } from '../admin-overall-password/admin-overall-password.component';
import { AdminOverallPasswordService } from '../admin-overall-password/admin-overall-password.service';

@Component({
  selector: 'app-admin-overall-modal',
  templateUrl: './admin-overall-modal.component.html',
  styleUrls: ['./admin-overall-modal.component.scss'],
  standalone: true,
  imports: [
    CdkScrollable,
    MatDialogContent,
    MatTabGroup,
    MatTab,
    AdminOverallPasswordComponent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
  ],
})
export class AdminOverallModalComponent implements OnInit {
  activeTabIndex = 0;
  isSaveAvailable = false;

  constructor(
    private adminOverallPasswordService: AdminOverallPasswordService,
    private alertService: AlertService,
  ) {}

  ngOnInit(): void {}

  save(): void {
    this.isSaveAvailable = false;

    switch (this.activeTabIndex) {
      case 0:
        this.adminOverallPasswordService.savePassword$().subscribe(() => {
          this.alertService.success($localize`Saved successfully`);
          this.isSaveAvailable = true;
        }, (err: HttpErrorResponse) => {
          this.alertService.error((Object.values(err.error)[0] as string[]).join(' '), 10000);
          this.isSaveAvailable = true;
        });
        break;
      case 1:
      // tbc
        break;
      default:
        console.error('Admin overall modal tab not found.');
    }
  }

  tabIndexChange(index: number): void {
    this.activeTabIndex = index;
  }

  passwordValid(valid: boolean): void {
    this.isSaveAvailable = this.activeTabIndex === 0 && valid;
  }
}


