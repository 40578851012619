import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { UserSessionService } from './shared/services/user-session.service';
import { GTMService } from './shared/services/gtm.service';

let hideLoading = () => {
  hideLoading = () => {};
  setTimeout(() => {
    const p = document.getElementById("global-loader") as HTMLElement;
    (p.nextSibling as HTMLElement).style.display = '';
    document.body.removeChild(p);
    document.body.style.overflow = '';
  });
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private router: Router,
    private userSessionService: UserSessionService,
    private trackingService: GTMService,
  ) {}

  ngOnInit(): void {
    const userName: string | null = this.userSessionService.getUserName();
    this.trackingService.initialize();

    if (userName) {
      this.router.events.forEach((item) => {
        if (item instanceof NavigationEnd) {
          this.trackingService.track({
            event: 'page',
            pageName: item.url,
            userId: userName,
          });
        }
      });
      this.trackingService.track({
        event: 'login',
        userId: userName,
      });
    } else {
      console.error(`Couldn't get userSession userName`);
    }
  }

  ngAfterViewInit(): void {
    hideLoading();
  }
}
