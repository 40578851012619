import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { ArrowComponent } from '../../../shared/components/arrow/arrow.component';
import { AlertsCounterComponent } from '../../../shared/components/card/components/alerts-counter/alerts-counter.component';
import { GaugeComponent } from '../../../shared/components/gauge/gauge.component';
import { NoDataSizeEnum } from '../../../shared/components/no-data/enums/no-data-size.enum';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { RobotStatusBadgeComponent } from '../../../shared/components/robot-status-badge/robot-status-badge.component';
import { ROBOT_SLUG_FANUC } from '../../../shared/constants/robot-slug-fanuc.constant';
import { routingPathReport } from '../../../shared/constants/routing-path.constant';
import { ColorEnum } from '../../../shared/enums/color.enum';
import { RobotStatusLevelEnum } from '../../../shared/enums/robot-status-level.enum';
import {
  ApiResourceDeviceConfigurationService,
} from '../../../shared/modules/api/services/api-resource-device-configuration/api-resource-device-configuration.service';
import { ApiResourceDevicesService } from '../../../shared/modules/api/services/api-resource-devices/api-resource-devices.service';
import { ApiParametersService } from '../../../shared/modules/api/services/api-resource-parameters/api-parameters.service';
import { ApiWebsocketService } from '../../../shared/modules/api/services/api-websocket.service';
import { TimeFormatPipe } from '../../../shared/pipes/time-format/time-format.pipe';
import { PreviousCurrentShiftService } from '../../../shared/services/previous-current-shift/previous-current-shift.service';
import { DevicesStore } from '../../../store/devices/devices.store';
import { DashboardService } from '../../devices/dashboard.service';
import { ReportsInterface } from '../../report/models/report.model';
import { ReportOverallComponent } from '../../report/report-overall/report-overall.component';
import { ReportService } from '../../report/report.service';
import { ScoreInterface } from '../../score/interfaces/score.interface';
import { ScoreService } from '../../score/score.service';
import { TileHeaderComponent } from '../../tile/header/tile.header.component';
import { TileProgramComponent } from '../../tile/program/tile.program.component';
import { RobotAbstractComponent } from '../robot-abstract.component';
import { RobotFanucService } from './robot-fanuc.service';

@Component({
  selector: 'app-robot-fanuc',
  templateUrl: './robot-fanuc.component.html',
  styleUrls: ['../robot-new.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    RobotFanucService,
  ],
  standalone: true,
  imports: [
    CommonModule,
    PageHeaderComponent,
    TileHeaderComponent,
    TileProgramComponent,
    RobotStatusBadgeComponent,
    AlertsCounterComponent,
    MatIcon,
    GaugeComponent,
    ArrowComponent,
    ReportOverallComponent,
    TimeFormatPipe,
  ],
})
export class RobotFanucComponent extends RobotAbstractComponent implements OnInit {
  SLUG: typeof ROBOT_SLUG_FANUC;
  scoreData: ScoreInterface;
  isScoreDataEmpty = false;
  reportConfig = null;
  public hasSpeedOverride: boolean;

  ColorEnum = ColorEnum;

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    protected apiService: ApiParametersService,
    protected apiWebsocketService: ApiWebsocketService,
    protected apiResourceDevicesService: ApiResourceDevicesService,
    protected activatedRoute: ActivatedRoute,
    protected reportService: ReportService,
    protected robotService: RobotFanucService,
    protected devicesStore: DevicesStore,
    protected dialog: MatDialog,
    public dashboardService: DashboardService,
    protected scoreService: ScoreService,
    private previousCurrentShiftService: PreviousCurrentShiftService,
    protected apiResourceDeviceConfigurationService: ApiResourceDeviceConfigurationService,
  ) {
    super(
      changeDetectorRef,
      apiService,
      apiWebsocketService,
      apiResourceDevicesService,
      activatedRoute,
      reportService,
      robotService,
      devicesStore,
      apiResourceDeviceConfigurationService,
      dialog,
    );
  }

  get emptyReport(): ReportsInterface {
    return {
      counters: {
        overall: [
          { status: $localize`Working`, time: 0, count: 0, percentage: 0, color: '#3fa45b' },
          { status: $localize`Not working`, time: 0, count: 0, percentage: 0, color: '#dc0d0e' },
        ],
      },
      timeline: {
        overall: [[], []],
      },
      summary: {
        overall: undefined,
      },
      pieChart: {
        overall: undefined,
      },
    };
  }

  ngOnInit(): void {
    this.setInitialData();
    this.previousCurrentShiftService.getShifts$(this.deviceId).subscribe(
      shifts => {
        if (shifts?.current) {
          this.onDateRange({
            dateFrom: shifts.current.start,
            // -1 second to avoid overlapping with next shift
            dateTo: new Date(+shifts.current.end - 1000),
            timeName: $localize`Current shift`,
          });
        } else {
          this.isCurrentShift = false;
          this.clearData();
        }
      },
    );
    this.changeDetectorRef.detectChanges();

    this.scoreService.getScoreData$(this.device.id).subscribe((scoreData: ScoreInterface) => {
      this.scoreData = scoreData;
      this.changeDetectorRef.detectChanges();
    }, () => {
      this.isScoreDataEmpty = true;
      this.changeDetectorRef.detectChanges();
    });

    this.hasSpeedOverride = false;
    if (this.device?.displayed_parameters) {
      this.hasSpeedOverride = this.device?.displayed_parameters.findIndex(v => {
        return v.slug === 'fnc_speed_override';
      }) !== -1;
    }
  }

  get routingPathUtilization() {
    return routingPathReport(this.deviceId);
  }
}
