<div class="account-page">
  <div class="kgn-container kgn-grid">
    <div class="account-page__form">
      <div class="account-redirect-page-info">
        <div class="account-page-logo-redirect"></div>

        <p class="account-redirect-page-paragraph">
          <span class="account-redirect-page-paragraph--spacious" i18n>Dear customer!</span><br />
          <span i18n>Out of the concern to improve the quality of our services,<br />
          we have migrated Kogena platform to the new domain:</span><br>
        </p>

        <pre class="
                account-redirect-page-preformatted
                account-redirect-page-preformatted--outdated
              ">cobotmonitor.com</pre>

        <pre
          (click)="redirectToNewDomain()"
          class="
                  account-redirect-page-preformatted
                  account-redirect-page-preformatted--updated
                "
        >app.kogena.com</pre>

        <p class="account-redirect-page-paragraph" i18n>
          The Platform will ask you to log in again.<br/>
          The credentials stay the same.
        </p>

        <p class="account-redirect-page-paragraph" i18n>Please use the new address for using the Kogena Platform.</p>

        <button
          (click)="redirectToNewDomain()"
          mat-button
          class="account-redirect-page-button"
        >
          <ng-container i18n>Redirect me to app.kogena.com</ng-container>
          <mat-icon class="material-icons-outlined">exit_to_app</mat-icon>
        </button>

        <p class="account-redirect-page-paragraph">
          <ng-container i18n>In case of any problems please contact us via e-mail</ng-container>
          <a
            href="mailto:{{ supportEmail }}"
            title=""
            class="account-redirect-page-email"
          >{{ supportEmail }}</a>
        </p>
      </div>
    </div>
  </div>
  <div class="account-page-bg"></div>
</div>
