<div
  class="form-select form-select--dropdownPositionLeft"
  [class.form-select--dropdownOpened]="isDropdownOpened"
>
  <div
    appClickOutside
    (clickOutside)="onDropdownToggle(true)"
    class="form-select-container"
  >
    <button
      (click)="options && !error && onDropdownToggle(isDropdownOpened)"
      [disabled]="!label && !error"
      [class.btn__select]="!error"
      [class.btn__error]="!!error"
      class="btn"
      matRipple
    >
      <ng-container *ngIf="!error; else hasError">
        <span class="form-select-button__text" *ngIf="label; else loading">{{ label }}</span>
      </ng-container>
      <ng-template #loading>
        <mat-progress-spinner
          mode="indeterminate"
          [diameter]="14"
        ></mat-progress-spinner>
        <span class="loading-text" i18n>Loading&hellip;</span>
      </ng-template>
      <ng-template #hasError>
        <span class="form-select-button__text" [title]="error">
          <ng-container i18n>Error</ng-container>: {{ error }}
        </span>
      </ng-template>
    </button>

    <div class="form-select-dropdown" *ngIf="options && !error">
      <div *ngIf="autocompleteFormControl" class="form-select-autocomplete">
        <app-form-search
          [formControl]="autocompleteFormControl"
          [isMinimalVersion]="true"
        ></app-form-search>
      </div>
      <ng-scrollbar [autoHeightDisabled]="false">
        <a
          *ngFor="let option of (options | filter: autocompleteFormControl?.value: 'label')"
          (click)="onLinkClick($event, option)"
          class="form-select-dropdown__link"
          [class.form-select-dropdown__link--active]="option.value === currentDeviceId"
          [routerLink]="option.additionalData.url"
        >
          <div class="select-option-status">
            <app-device-status-icon [device]="option.additionalData.device"></app-device-status-icon>
          </div>
          {{ option.label }}
        </a>
      </ng-scrollbar>
    </div>
  </div>
</div>

