<div class="modal">
  <mat-dialog-content>
    <div class="contact-modal__logo"></div>
    <h3 class="contact-modal__header">KOGENA sp. z o.o</h3>
    <article class="contact-modal__content">
      <a
        href="https://www.google.com/maps/place/Plac+Solny+15,+50-062+Wroc%C5%82aw/@51.1089853,17.0270014,17z/data=!3m1!4b1!4m5!3m4!1s0x470fc274ef5b4e13:0xfa963c44e2350502!8m2!3d51.108982!4d17.02919"
        target="_blank"
        rel="noreferrer"
      >
        <p>Pl. Solny 15, 50-062 Wrocław, Poland</p>
        <p>VAT: 8971852678, KRS: 0000721255</p>
      </a>
      <a href="tel:+48 533 216 454">+48 533 216 454</a>
      <a href="mailto:kontakt@kogena.com">kontakt&#64;kogena.com</a>
    </article>
  </mat-dialog-content>
  <div class="contact-modal__footer">
    <mat-dialog-actions align="end">
      <button
        mat-button
        mat-dialog-close
        i18n
      >
        Close
      </button>
    </mat-dialog-actions>
  </div>
</div>
