import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiUrlLogin, apiUrlLogout, apiUrlResetPassword } from '../../constants/api-url.constant';
import { ApiAccountLoginInterface } from '../../interfaces/api-account/api-account-login.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiResourceAccountService {
  constructor(
    private httpClientService: HttpClient,
  ) {}

  login$(value: { username: string, password: string }): Observable<ApiAccountLoginInterface> {
    return this.httpClientService.post<ApiAccountLoginInterface>(apiUrlLogin, value);
  }

  resetPassword$(value): Observable<void> {
    return this.httpClientService.post<void>(apiUrlResetPassword, value);
  }

  logout$(): Observable<void> {
    return this.httpClientService.post<void>(apiUrlLogout, {});
  }
}
