import { Routes } from '@angular/router';
import { AllParametersResolver } from '../../../shared/resolvers/all-parameters.resolver';
import { AvailableViewResolver } from '../../../shared/resolvers/available-view.resolver';
import { DeviceResolver } from '../../../shared/resolvers/device.resolver';
import { RobotStatusOverallResolver } from '../../../shared/resolvers/robot-status-overall.resolver.service';
import { RobotStatusResolver } from '../../../shared/resolvers/robot-status.resolver.service';
import { RobotFanucPageComponent } from './robot-fanuc-page.component';

const ROBOT_FANUC_ROUTES: Routes = [
  {
    path: '',
    component: RobotFanucPageComponent,
    resolve: {
      device: DeviceResolver,
      availableViews: AvailableViewResolver,
      parameters: AllParametersResolver,
      robotStatus: RobotStatusResolver,
      robotStatusOverall: RobotStatusOverallResolver,
    },
  },
];

export default ROBOT_FANUC_ROUTES;
