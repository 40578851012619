import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './modules/layout/layout.component';
import { AccountLoginPageComponent } from './pages/account-page/account-login-page/account-login-page.component';
import { AccountRedirectPageComponent } from './pages/account-page/account-redirect-page/account-redirect-page.component';
import { AccountResetPasswordPageComponent } from './pages/account-page/account-reset-password-page/account-reset-password-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import ROBOT_FANUC_ROUTES from './pages/robot-page/robot-fanuc-page/robot-fanuc-page.routes';
import {
  routingPathAlarms,
  routingPathAlarmsYaskawa,
  routingPathBase,
  routingPathCharts,
  routingPathErrors,
  routingPathFlightRecords,
  routingPathKPI,
  routingPathKPIHutchinson,
  routingPathKPIHutchinsonTools,
  routingPathKPIKassow,
  routingPathKPIScanfil,
  routingPathLifetimeYaskawa,
  routingPathLogin,
  routingPathLogs,
  routingPathNewDomainRedirect,
  routingPathOEE,
  routingPathPalletization,
  routingPathPalletizationHMI,
  routingPathPalletizationUniveler,
  routingPathPlayground,
  routingPathReport,
  routingPathResetPassword,
  routingPathRobotEpson,
  routingPathRobotFanuc,
  routingPathRobotKassow,
  routingPathRobotKawasaki,
  routingPathRobotTechman,
  routingPathRobotUr,
  routingPathRobotYaskawa,
  routingPathScore,
  routingPathServicePacks,
  routingPathSettings,
} from './shared/constants/routing-path.constant';

const APP_ROUTES: Routes = [
  { path: routingPathLogin, component: AccountLoginPageComponent },
  { path: routingPathResetPassword, component: AccountResetPasswordPageComponent },
  { path: routingPathNewDomainRedirect, component: AccountRedirectPageComponent },
  {
    path: '',
    component: LayoutComponent,
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: routingPathBase,
        loadChildren: () => import('./pages/devices-page/devices-page.module')
          .then(m => m.DevicesPageModule),
      },
      // *** Robot ***
      {
        path: routingPathRobotUr(),
        loadChildren: () => import('./pages/robot-page/robot-ur-page/robot-ur-page.module')
          .then((m) => m.RobotUrPageModule),
      },
      {
        path: routingPathRobotFanuc(),
        children: ROBOT_FANUC_ROUTES,
      },
      {
        path: routingPathRobotKawasaki(),
        loadChildren: () => import('./pages/robot-page/robot-kawasaki-page/robot-kawasaki-page.module')
          .then((m) => m.RobotKawasakiPageModule),
      },
      {
        path: routingPathSettings(),
        loadChildren: () => import('./pages/settings-page/settings-page.module')
          .then((m) => m.SettingsPageModule),
      },

      // *** Robot ***
      {
        path: routingPathRobotYaskawa(),
        loadChildren: () => import('./pages/robot-page/robot-yaskawa-page/robot-yaskawa-page.module')
          .then((m) => m.RobotYaskawaPageModule),
      },
      {
        path: routingPathRobotEpson(),
        loadChildren: () => import('./pages/robot-page/robot-epson-page/robot-epson-page.module')
          .then((m) => m.RobotEpsonPageModule),
      },
      {
        path: routingPathRobotTechman(),
        loadChildren: () => import('./pages/robot-page/robot-techman-page/robot-techman-page.module')
          .then((m) => m.RobotTechmanPageModule),
      },
      {
        path: routingPathRobotKassow(),
        loadChildren: () => import('./pages/robot-page/robot-kassow-page/robot-kassow-page.module')
          .then((m) => m.RobotKassowPageModule),
      },
      // *** Report ***
      {
        path: routingPathReport(),
        loadChildren: () => import('./pages/report-page/report-page.module').then((m) => m.ReportPageModule),
      },

      // *** Alarms ***
      {
        path: routingPathAlarms(),
        loadChildren: () => import('./pages/alarms-page/alarms-page.module').then((m) => m.AlarmsPageModule),
      },
      {
        path: routingPathAlarmsYaskawa(),
        loadChildren: () => import('./modules/alarms/alarms-yaskawa/alarms-yaskawa.module').then((m) => m.AlarmsYaskawaModule),
      },

      // *** Logs ***
      {
        path: routingPathLogs(),
        loadChildren: () => import('./pages/logs-page/logs-page.module').then((m) => m.LogsPageModule),
      },

      // *** Measurements/Charts ***
      {
        path: routingPathCharts(),
        loadChildren: () => import('./modules/measurements/measurements.module').then((m) => m.MeasurementsModule),
      },

      // *** Palletization ***
      {
        path: routingPathPalletization(),
        loadChildren: () => import('./modules/palletization/palletisation/palletisation.module').then((m) => m.PalletisationModule),
      },
      {
        path: routingPathPalletizationUniveler(),
        loadChildren: () => import('./modules/palletization/palletisation-unilever/palletisation-unilever.module')
          .then((m) => m.PalletisationUnileverModule),
      },
      {
        path: routingPathPalletizationHMI(),
        loadChildren: () => import('./pages/palletization-page/palletization-hmi-page/palletization-hmi-page.module')
          .then((m) => m.PalletizationHmiPageModule),
      },

      // *** Lifetime ***
      {
        path: routingPathLifetimeYaskawa(),
        loadChildren: () => import('./modules/lifetime/yaskawa-lifetime/yaskawa-lifetime.module').then((m) => m.YaskawaLifetimeModule),
      },

      // *** KPI ***
      {
        path: routingPathKPI(),
        loadChildren: () => import('./pages/kpi-page/kpi-page/kpi-page.module')
          .then((m) => m.KpiPageModule),
      },
      {
        path: routingPathKPIScanfil(),
        loadChildren: () => import('./pages/kpi-page/kpi-scanfil-page/kpi-scanfil-page.module')
          .then((m) => m.KpiScanfilPageModule),
      },
      {
        path: routingPathKPIKassow(),
        loadChildren: () => import('./pages/kpi-page/kpi-kassow-page/kpi-kassow-page.module')
          .then((m) => m.KpiKassowPageModule),
      },
      {
        path: routingPathKPIHutchinson(),
        loadChildren: () => import('./pages/kpi-page/kpi-hutchinson-page/kpi-hutchinson-page.module')
          .then((m) => m.KpiHutchinsonPageModule),
      },
      {
        path: routingPathKPIHutchinsonTools(),
        loadChildren: () => import('./pages/kpi-page/kpi-hutchinson-tools-page/kpi-hutchinson-tools-page.module')
          .then((m) => m.KpiHutchinsonToolsPageModule),
      },

      // *** OEE ***
      {
        path: routingPathOEE(),
        loadChildren: () => import('./pages/oee-page/oee-page.module')
          .then((m) => m.OeePageModule),
      },

      // *** Errors aka Diagnostic ***
      {
        path: routingPathErrors(),
        loadChildren: () => import('./pages/errors-page/errors-page.module').then(m => m.ErrorsPageModule),
      },

      // *** Troubleshooting ***
      {
        path: routingPathFlightRecords(),
        loadChildren: () =>
          import('./pages/flight-records-page/flight-records-page.module').then(m => m.FlightRecordsPageModule),
      },

      // *** Service Packs ***
      {
        path: routingPathServicePacks(),
        loadChildren: () => import('./modules/service-pack/service-pack.module').then(m => m.ServicePackModule),
      },

      // *** Score ***
      {
        path: routingPathScore(),
        loadChildren: () =>
          import('./pages/score-page/score-page.module').then(m => m.ScorePageModule),
      },

      // *** Playground ***
      {
        path: routingPathPlayground(),
        loadChildren: () =>
          import('./pages/playground-page/playground-page.module').then(m => m.PlaygroundPageModule),
      },
    ],
  },
  // *** 404 Page ***
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
