export const ROBOT_SLUG_FANUC: {
  CURRENT_PROGRAM_NAME: string; // Nowy slug
  PRODUCTION_PROGRAM_NAME: string; // To odpowiada PROGRAM_NAME w UR
  SPEED_OVERRIDE_NAME: string;
  PAYLOAD: string;
  TCP_POSITION: string;
  PAYLOAD_COG: string;
  PAYLOAD_COG_X: string;
  PAYLOAD_COG_Y: string;
  PAYLOAD_COG_Z: string;
  JOINT_TEMPERATURES: string;
} = {
  CURRENT_PROGRAM_NAME: 'fnc_current_program_name',
  PRODUCTION_PROGRAM_NAME: 'fnc_production_program_name',
  SPEED_OVERRIDE_NAME: 'fnc_speed_override',
  PAYLOAD: 'fnc_active_payload_mass',
  TCP_POSITION: 'fnc_active_user_tool',
  PAYLOAD_COG: 'virtual_payload_cog',
  PAYLOAD_COG_X: 'fnc_active_payload_cog_x',
  PAYLOAD_COG_Y: 'fnc_active_payload_cog_y',
  PAYLOAD_COG_Z: 'fnc_active_payload_cog_z',
  JOINT_TEMPERATURES: 'fnc_joint_temperatures',
};
