<div class="account-page">
  <div class="kgn-container kgn-grid">
    <div class="account-page__form">
      <div class="account-page__form-header kgn-grid kgn-gap-2">
        <a href="https://kogena.com" class="account-page-logo"></a>
        <div class="desc" i18n>Remote <span class="red">diagnostics <br>and maintenance Platform</span><br> for industrial and collaborative robots</div>
      </div>
      <div class="account-container__content__panel__text" i18n>Reset password</div>
      <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()" class="login-form">
        <div class="login-form__form-group">
          <app-form-input
            [formControl]="email"
            placeholder="E-mail" i18n-placeholder
            type="email"
            browserAutocomplete="email"
          ></app-form-input>
        </div>
        <button
          type="submit"
          class="login-form__submit"
          matRipple
          i18n
        >Send</button>
        <div class="login-form__subtext">
          <a (click)="goToLogin()" i18n>Back to login</a>
        </div>
      </form>
    </div>
  </div>
  <div class="account-page-bg"></div>
</div>
