import { CommonModule, DecimalPipe, I18nPluralPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom, Provider } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { devTools } from '@ngneat/elf-devtools';
import { browserTracingIntegration, init, TraceService } from '@sentry/angular';
import { NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { AuthInterceptor } from './app/core/interceptors/auth.interceptor';
import { GlobalErrorHandlerService } from './app/core/services/global-error-handler.service';
import { AdminOverallModule } from './app/modules/admin/admin-overall/admin-overall.module';
import { ENVIRONMENT } from './environments/environment';

const sentryProviders: Provider[] =
  ENVIRONMENT.sentryDsn ? [
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
  ] : [];

const setupSentry: () => void = () => {
  if (ENVIRONMENT.sentryDsn) {
    init({
      dsn: ENVIRONMENT.sentryDsn,
      integrations: [
        browserTracingIntegration(),
      ],
      tracePropagationTargets: [ENVIRONMENT.origin],
      tracesSampleRate: ENVIRONMENT.envName === 'DEVELOPMENT' ? 1 : 0.05,
    });
  }

  if (ENVIRONMENT.production) {
    enableProdMode();
  }
};

const setupApp: () => void = () => {
  bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(
        BrowserModule, CommonModule, FormsModule, MatSnackBarModule, // used by global error.interceptor
        AppRoutingModule, AdminOverallModule, MatSnackBarModule,
      ),
      I18nPluralPipe,
      DecimalPipe, // @TODO initialize on module feature level
      {
        provide: 'googleTagManagerId',
        useValue: ENVIRONMENT.googleTagManagerId,
      },
      {
        provide: MAT_DIALOG_DEFAULT_OPTIONS,
        useValue: {
          ...new MatDialogConfig(),
          maxHeight: '70vh',
        } as MatDialogConfig,
      },
      {
        provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
        useValue: {
          appearance: 'fill',
        },
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true,
      },
      {
        provide: NG_SCROLLBAR_OPTIONS,
        useValue: {
          visibility: 'always',
          trackClickScrollDuration: 0,
        },
      },
      {
        provide: ErrorHandler,
        useClass: GlobalErrorHandlerService,
      },
      provideHttpClient(withInterceptorsFromDi()),
      ...sentryProviders,
      provideAnimations(),
    ],
  })
    .then(() => {
      devTools({
        maxAge: 50,
      });
    })
    .catch((err) => console.error(err));
};

setupSentry();
setupApp();
