<div class="admin-overall-password">
  <p class="admin-overall-password__description" i18n>
    Password must contain at least 8 characters.<br>
    Password must contain at least 1 digit.<br>
    Password must contain at least 2 letters.<br>
    Password must contain at least 1 upper case letter.<br>
    Password must contain at least 1 special character.
  </p>
  <form>
    <div class="admin-overall-password__row">
      <label class="admin-overall-password__label" for="passwordCurrent" i18n>Current password</label>
      <input
        [formControl]="$any(formGroup.get('current_password'))"
        class="admin-overall-password__input"
        id="passwordCurrent"
        type="password"
        autocomplete="current-password"
      />
    </div>
    <div class="admin-overall-password__row">
      <label class="admin-overall-password__label" for="passwordNew" i18n>New password</label>
      <input
        [formControl]="$any(formGroup.get('password'))"
        class="admin-overall-password__input"
        id="passwordNew"
        type="password"
        autocomplete="new-password"
      />
    </div>
    <div class="admin-overall-password__row">
      <label
        class="admin-overall-password__label"
        for="passwordNewConfirmation"
        i18n>Confirm new password</label>
      <input
        [formControl]="$any(formGroup.get('confirm'))"
        class="admin-overall-password__input"
        id="passwordNewConfirmation"
        type="password"
        autocomplete="new-password"
      />
    </div>
  </form>
</div>
