import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { Subject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { DeviceShortInterface } from '../../interfaces/device.interface';
import { FormSearchComponent } from '../../modules/form/components/form-search/form-search.component';
import { FilterPipe } from '../../pipes/filter/filter.pipe';
import { DeviceStatusIconComponent } from '../device-status-icon/device-status-icon.component';
import { DevicesDropdownDataService } from './services/devices-dropdown-data/devices-dropdown-data.service';
import { DevicesDropdownNavigationService, GetUrlInterface } from './services/devices-dropdown-navigation/devices-dropdown-navigation.service';

interface DeviceDropdownOptionInterface {
  label: string;
  value: string | number | null | object;
  additionalData: GetUrlInterface;
}

@Component({
  standalone: true,
  selector: 'app-device-dropdown',
  templateUrl: 'devices-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: [
    './devices-dropdown.component.scss',
    '../../modules/form/components/form-select/form.scss',
    '../../modules/form/components/form-select/form-select.component.scss',
  ],
  imports: [
    CommonModule,
    DeviceStatusIconComponent,
    FilterPipe,
    FormSearchComponent,
    MatProgressSpinnerModule,
    NgScrollbarModule,
    RouterModule,
  ],
  providers: [
    DevicesDropdownDataService,
    DevicesDropdownNavigationService,
  ],
})
export class DevicesDropdownComponent implements OnInit, OnDestroy {
  @Input('currentDeviceId') set currentDeviceIdSetter(value: string) {
    this.currentDeviceId = value;
    this.getLabel();
  }

  currentDeviceId: string;
  options: DeviceDropdownOptionInterface[];
  autocompleteFormControl: FormControl<string | null>;
  devices: DeviceShortInterface[];
  isDropdownOpened = false;
  label = '';
  error = '';

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private dropdownDataService: DevicesDropdownDataService,
    private dropdownNavigationService: DevicesDropdownNavigationService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.autocompleteFormControl = new FormControl('');
    this.fetchStatuses();
  }

  fetchStatuses() {
    this.dropdownDataService.getDropdownDevices$().pipe(
      takeUntil(this.destroy$),
      mergeMap(async (devices: DeviceShortInterface[]) => {
        const options = await this.getFormSelectOptions(devices);
        return { devices, options };
      }),
    ).subscribe({
      next: ({ devices, options }) => {
        this.devices = devices;
        this.options = options;
        this.getLabel();
        this.changeDetectorRef.detectChanges();
      },
      error: (err) => {
        this.error = (err ? err + '' : null) || $localize`Unknown error`;
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  onLinkClick(event: MouseEvent, option: DeviceDropdownOptionInterface): void {
    event.preventDefault();
    this.dropdownNavigationService.navigateToRobot(option.additionalData);
    this.onDropdownToggle(true);
  }

  onDropdownToggle(close: boolean): void {
    this.isDropdownOpened = !close;
    if (this.isDropdownOpened) {
      this.fetchStatuses();
    }
    this.changeDetectorRef.detectChanges();
  }

  private getLabel(): void {
    if (this.options) {
      this.label = this.options.find(option => option.value === this.currentDeviceId)?.label || '--';
    }
  }

  private async getFormSelectOptions(
    devices: DeviceShortInterface[],
  ): Promise<DeviceDropdownOptionInterface[]> {
    return Promise.all(
      devices.map(async (device: DeviceShortInterface) => ({
        label: `${device.name} ${device.robot_serial_number}`,
        value: device.id,
        additionalData: await this.dropdownNavigationService.getUrl(device),
      })),
    );
  }
}
